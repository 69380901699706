@import "src/shared/styles/variables";

.MainWrap {
    margin: 50px 27px;
}

.CloseControl {

    position: absolute;
    top: 9px;
    left: 13px;
    height: 37px;
    width: 37px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    &:hover {
        background: #D9D9D9;
    }

    img {
        width: 12px;
    }
}

.Title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.02em;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 29px;
}

.ServicesWrap {
    display: flex;
    flex-direction: column;
}

.Service {
    width: 273px;
    padding: 14px 30px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $white;
    margin-bottom: 17px;
    background: $green !important;
    outline: none !important;
    border: none !important;
    border-radius: 8px;

    &:hover {
        background: $green;
        outline: none;
        filter: brightness(95%);
    }

    &:active {
        background: $green;
        outline: none;
    }
}

.Text {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    text-align: center;
    letter-spacing: 0.02em;
}
