@import "src/shared/styles/variables";

.CompanyWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Name {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 9px;
}

.Industry {
    font-size: 18px;
    letter-spacing: 0.02em;
    margin-bottom: 14px;
}

.About {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $lightBlack;
    margin-bottom: 24px;
}

.HiredStats {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    margin-bottom: 24px;
}

.Underlined {
    text-decoration: underline;
    margin: 0 3px;
}

.VerifiedBusiness {
    margin-bottom: 15px;
}

.Location {
    margin-bottom: 26px;
}

.Services {
    margin-bottom: 36px;
}

.QuickFacts {
    margin-bottom: 16px;
}

.Gallery {
    margin-right: -21px;
    margin-left: -21px;
    margin-bottom: 25px;
}
