@import "shared/styles/variables";

.MainWrap {
    display: flex;
    flex-direction: column;
}

.Title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    margin-bottom: 14px;
}

.Service {
    display: flex;

    .Icon {
        margin-right: 14px;

        img {
            width: 26px;
            height: 26px;
        }
    }

    .Text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: $lightBlack;
    }
}
