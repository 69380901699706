.OverlayWrap {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
}

.SpinnersWrap {
    height: calc(50vh - 10px);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}




