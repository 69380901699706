.SubmitButton {
    width: 273px;
    height: 52px;
    background: $green !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    outline: none !important;
    border: none !important;
    margin-bottom: 30px;
    color: $white;
    font-weight: 600;
    letter-spacing: 0.7px;

    &:hover {
        filter: brightness(95%);
    }

    &:disabled {
        pointer-events: none;
        background: $darkGrey !important;
    }
}
