@import "src/shared/styles/variables";

.MainWrap {
    position: fixed;
    padding-top: 18vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 600px;
    background: $white;

    .Title {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.02em;
        margin-bottom: 6px;
    }

    .SubTitle {
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $lightBlack;
        width: 201px;
        margin-bottom: 41px;
    }

    .ImageBackground {
        width: 180px;
        height: 180px;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }

    .Image {
        width: 112px;
        height: 112px;
    }

    .BottomWrap {

        .Disclaimer {
            width: 330px;
            color: $lightBlack;
            text-align: center;
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 42px;

            .DisclaimerTitle {
                font-weight: 600;
            }
        }

        .Underlined {
            margin: 0 3px;
            text-decoration: underline;
            color: $lightBlack !important;
        }
    }
}

@media (max-width: 450px) {

    .MainWrap {
        padding-top: 80px;
    }
}
