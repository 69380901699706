.MainContainer {
    width: 100%;
    padding: 0 31px;
}

.MainWrap {
    padding-bottom: 20px;
    border-bottom: 1px solid #CBCBCB;
}

.Hidden {
    display: none;
}
