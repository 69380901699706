@import "src/shared/styles/formInputs";

.MainWrap {
    padding-bottom: 12px;
    margin-bottom: 0;

    .Title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        margin-bottom: 12px;
    }

    .Control {
        margin-bottom: 12px;
    }

    .MultipleChoice {
        padding-top: 10px;
        padding-bottom: 13px;
        margin-bottom: 0;
    }

    .SingleChoice {
        padding-top: 10px;
        padding-bottom: 13px;
        margin-bottom: 0;
    }

    .DatePickerWrap {
        position: relative;

        .DatePickerIcon {
            left: 19px;
            top: 21px;
            position: absolute;
            width: 28px;
            height: 28px;
            z-index: 999;
            pointer-events: none;
        }
    }

    .DatePicker {
        outline: none !important;
        border: none !important;
        width: 100%;
        height: 68px;
        box-shadow: 0 2px 32px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        color: #161616 !important;
        padding: 11px 20px;
        cursor: pointer;
        padding-left: 62px;
    }

    .Textarea {
        height: 135px;
        padding: 15px 20px;
    }

    .Input {
        outline: none !important;
        border: none !important;
        width: 100%;
        height: 68px;
        box-shadow: 0 2px 32px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        color: #161616 !important;
        padding: 11px 20px;
    }
}
