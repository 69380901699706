@import "src/shared/styles/variables";
@import "src/shared/styles/formInputs";
@import "src/shared/styles/buttons";

.MainWrap {

    .Back {
        cursor: pointer;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: $lightBlackAlt;
        margin-left: 10px;
        margin-top: 5px;
        &:hover {
            filter: brightness(50%);
        }
    }

    .Refferant {
        padding: 30px 0;
        margin: 0  30px;
        padding-top: 6px;
        border-bottom: 1px solid #CBCBCB;
    }

    .Messages {
        padding: 15px 30px;
    }

    .Message {
        margin-bottom: 30px;

        .Title {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 5px;
        }

        .Subtitle {
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.02em;
            color: $lightBlackAlt;
            margin-bottom: 10px;
        }

        .Textarea {
            font-size: 14px;
            height: 200px;
            padding: 15px;
        }
    }

    .SubmitWrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
