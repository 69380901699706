@import "src/shared/styles/variables";

.MainWrap {


    .Title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .Question {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: $lightBlackAlt;
        margin-bottom: 10px;
    }

    .Stars {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-end;

        .Star {
            font-size: 24px;
            margin-right: 2px;
            color: $darkGrey;
        }

        .StarFill {
            font-size: 24px;
            margin-right: 2px;
            color: $green;
        }

        .Unset {
            margin-left: 3px;
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            color: $lightBlack;
            margin-top: -4px;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
