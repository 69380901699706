@import "src/shared/styles/variables";

.MainWrap {
    margin-top: 15vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;

    .Title {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.02em;
        margin-bottom: 6px;
    }

    .SubTitle {
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        padding: 0 30px;
        letter-spacing: 0.02em;
        margin-bottom: 41px;
    }

    .ImageBackground {
        width: 180px;
        height: 180px;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }

    .Image {
        width: 112px;
        height: 112px;
    }
}

@media (max-width: 450px) {

    .MainWrap {
        padding-top: 80px;
    }
}
