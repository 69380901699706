.MainWrap {

    display: flex;
    align-items: center;

    .Image {
        height: 130px;
        width: 115px;
        object-fit: cover;
        border-radius: 16px;
        margin-right: 15px;
    }

    .Info {

        display: flex;
        flex-direction: column;

        .Name {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 10px;
        }

        .Title {
            font-size: 16px;
            letter-spacing: 0.02em;
            margin-bottom: 3px;
        }

        .Company {
            font-size: 16px;
            letter-spacing: 0.02em;
            margin-bottom: 14px;
        }
    }
}
