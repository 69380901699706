.AvatarContainer {
    width: 366px;
    height: 410px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    margin-bottom: 23px;
    object-fit: cover;
}

.Title {
    font-size: 20px;
    margin-top: 8px;
}

.GeneralInfoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 21px;
    border-bottom: 1px solid #CBCBCB;
    padding-bottom: 26px;
}

.Name {
    font-size: 20px;
    font-weight: bold;
}

.CompanyName {
    margin-top: 14px;
    border-radius: 30px;
    background: #FCEED8;
    padding: 12px 34px;
    font-size: 18px;
}

.Recommendations {
    margin-top: 22px;
}

