@import "src/shared/styles/variables";
@import "src/shared/styles/buttons";

.MainWrap {


    .Referrant {
        padding: 30px 0;
        margin: 0  30px;
        padding-top: 35px;
        border-bottom: 1px solid #CBCBCB;
    }

    .Reviews {
        padding: 20px 30px;
    }

    .NextWrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
