@import "shared/styles/variables";

.MainWrap {
    display: flex;
}

.IconWrap {
    margin-right: 11px;

    img {
        height: 32px;
        width: 32px;
        fill: $green;
    }
}

.ContentWrap {
    display: flex;
    flex-direction: column;

    .Title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
    }

    .Description {
        color: $lightBlack;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
}
