@import "src/shared/styles/variables";
@import "../../shared/styles/formInputs";

.MainContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: $white;
    overflow-y: auto;
}

.MainWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    background: $grey;
    margin: 8px 4px;
    padding-bottom: 26px;
    width: 413px;
}

.Disclaimer {
    color: $lightBlack;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 42px;

    .DisclaimerTitle {
        font-weight: 600;
    }
}

.Underlined {
    margin: 0 3px;
    text-decoration: underline;
    color: $lightBlack !important;
}

.Submit {
    width: 273px;
    height: 52px;
    background: $green !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    outline: none !important;
    border: none !important;
    margin-bottom: 30px;

    &:hover {
        filter: brightness(95%);
    }
}



