@import "src/shared/styles/variables";
@import "src/shared/styles/formInputs";
@import "src/shared/styles/buttons";

.MainWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 30px;
    padding-top: 70px;

    .Referrant {
        padding: 30px 0;
        margin: 0  30px;
        padding-top: 35px;
        border-bottom: 1px solid #CBCBCB;
    }
}



.Title {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
}

.Subtitle {
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $lightBlackAlt;
    margin-bottom: 30px;
}

.VerificationMethod {
    display: flex;
    flex-direction: column;
    width: 100%;

    input[type='radio'] {
        --border: #008379 !important;
        margin-bottom: 0.7rem;

        &:checked {
            --border: #008379 !important;
            --bc: #008379;
        }
    }
}

.VerificationDataForm {
    width: 100%;
    padding: 0 1.2rem;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.VerificationWrap {
    width: 100%;
    margin-bottom: 60px;

    .Data {
        display: flex;
        align-items: center;
    }

    .VerificationLabel {
        font-weight: 600;
        margin-right: 5px;
        letter-spacing: 1px;
        white-space: nowrap;
        margin-bottom: 0;
    }

    .Input{
        width: 100%;
        height: 48px;
        font-weight: 500;
    }

    .Errors {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 3px;
    }
}
