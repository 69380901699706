@import "src/shared/styles/variables";

.MainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.MainWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    background: $white;
    margin: 8px 4px;
    padding: 26px 23px;
    width: 413px;
    padding-bottom: 15px;
    margin-bottom: 130px;
}

.RequestQuoteContainer {
    position: fixed;
    pointer-events: none;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.RequestQuote {
    background: $green !important;
    color: $white;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    pointer-events: all;
    margin-bottom: 50px;
    width: 273px;
    height: 52px;
    outline: none !important;
    border: none !important;
    border-radius: 8px;

    &:hover {
        background: $green;
        filter: brightness(95%);
    }

    &:focus{
        outline: none !important;
    }
}

@media (max-width: 450px) {

    .MainWrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 16px;
        background: $white;
        margin: 8px 4px;
        padding: 26px 23px;
        padding-bottom: 66px;
        margin-bottom: 30px;
        width: calc(100% - 8px);
    }
}

