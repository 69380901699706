.MainWrap {
    height: 244px;
    width: 100%;
    display: flex;

    .Image {
        height: 100%;
        width: auto;
        min-width: 90%;
        object-fit: cover;
    }

    .SingleImage {
        width: 100%;
        object-fit: cover;
    }
}


