@import "shared/styles/variables";

.MainContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: $white;
    overflow-y: auto;
    color: $black;
}

.MainWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background: $grey;
    margin: 8px 4px;
    width: 413px;
    min-height: calc(100vh - 16px);
    padding: 40px;

    .Image {
        margin-top: 10vh;
        height: 25px;
        margin-bottom: 40px;
    }

    .Title {
        font-weight: 500;
        margin-bottom: 40px;
        letter-spacing: 0.02em;
        font-size: 18px;
    }

    .Text {
        margin-bottom: 40px;
        font-size: 18px;
    }
}

@media (max-width: 450px) {

    .MainWrap {
        width: calc(100% - 8px);
    }
}
