@import "~bootstrap/scss/bootstrap.scss";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~react-datepicker/dist/react-datepicker.css";

@import "src/shared/styles/variables";
@import "node_modules/toastr/toastr";

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $grey;
    color: $black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// gallery style overwrites

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
}

.image-gallery {
    width: 100%;
}

.image-gallery-icon:hover {
    color: $white;
}

// modal style overwrites

.modal-open .modal {
    background: #CFCFCFCC;
    display: flex !important;
    justify-content: center;
    padding-top: 24px;
}

.modal-content {
    border-radius: 16px;
    border: none;
}

.request-quote-modal {
    width: 327px;
}
