@import "src/shared/styles/variables";

.Image {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: 264px;
    width: 100%;
    object-fit: cover;
}

.GeneralWrap {
    width: 100%;
    padding: 8px 6px;
    padding-bottom: 26px;
}

.General {
    border-radius: 16px;
    background: $white;
    padding: 17px 25px;
    padding-bottom: 25px;
    width: 100%;
}

.GeneralTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.02em;
    margin-bottom: 9px;
}

.GeneralInfo {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $lightBlack;
}

.Back {
    cursor: pointer;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: $lightBlackAlt;
    margin-bottom: 9px;

    &:hover {
        filter: brightness(50%);
    }
}
