@import "src/shared/styles/variables";
@import "src/shared/styles/formInputs";

.MainWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    padding: 27px 31px;
}

.Label {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.04em;
    margin-bottom: 12px;
}

.ContactLabel {
    margin-top: 30px;
    margin-bottom: 22px;
}

.Input {
    width: 100%;
    margin-bottom: 21px;
    padding: 11px 20px;
    height: 68px;
}

.Check {
    margin-bottom: 20px;
}

.Hidden {
    display: none;
}

.PhoneInputContainer {

    .PhoneInput {
        width: 100% !important;
        margin-bottom: 21px !important;
        height: 68px !important;
        font-size: 1rem !important;
        border: none !important;
    }

    .FlagSelector {
        background: transparent !important;
        border: none !important;

        .selected-flag {
            background: transparent !important;
        }
    }
}
